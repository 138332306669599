import FrontBusiness from "@/business/FrontBusiness.js";
import CachedApi from "@/commons/CachedApi.js";
import Http from "@/commons/Http";
import ValidationUtils from "@/commons/ValidationUtils.js";

const path = 'api/ocorrencia';

export default {

  nomearEntidade(ocorrencia, code = true){
    return `Ocorrência ${code ? FrontBusiness.formatarCodigo(ocorrencia?.codigo) : ''}`;
  },

  async pagination(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/pagination?sort=${sort || 'o.cadastro'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async paginationV2(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/v2/pagination?sort=${sort || 'o.cadastro'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async listarPorEnvolvido(indicador = ''){
    return Http.get(`${path}/indicador-pessoal/${indicador}`);
  },

  async listarPorIndicadorReal(indicador = ''){
    return Http.get(`${path}/indicador-real/${indicador}`);
  },

  async getById(indicador){
    return Http.get(`${path}/${indicador}`);
  },

  async saveForm(dto, id = ''){
    return Http.post(`${path}/form/${id}`, dto);
  },

  async save(dto, id = ''){
    return Http.post(`${path}/${id}`, dto);
  },

  salvarAdiamento(ocorrencia, dto) {
    return Http.post(`${path}/${ocorrencia}/salvar-adiamento`, dto);
  },

  async getAuditorias(id, page, size){
    return Http.get(`${path}/${id}/auditorias?page=${page || 1}&size=${size || 10}`);
  },

  async regerarChecklist(id){
    return Http.post(`${path}/${id}/checklist`);
  },

  async listarPorRegistroAuxiliar(registro = ''){
    return Http.get(`${path}/registro-auxiliar/${registro}`);
  },

  async vinculos(id){
    return Http.get(`${path}/${id}/vinculos`);
  },

  async buscarPorCodigos(codigos) {
    if(!ValidationUtils.validateLongArray({values: codigos, min: 0, max: 99999999})){
      throw 'Intervalo Inválido';
    }
    return await Http.post(`${path}/codigos`, codigos);
  },

  async indicadorPessoalPossuiOcorrenciasAtivas(indicadorPessoal){
    return CachedApi.getCached('ocorrencia-'+indicadorPessoal, async () => {
      return Http.get(`${path}/indicador-pessoal/${indicadorPessoal}/possui-ocorrencias-ativas`);
    });
  },

  async indicadorRealPossuiOcorrenciasAtivas(indicadorReal){
    return CachedApi.getCached('ocorrencia-'+indicadorReal, async () => {
      return Http.get(`${path}/indicador-real/${indicadorReal}/possui-ocorrencias-ativas`);
    });
  },

  async registroAuxiliarPossuiOcorrenciasAtivas(registroAuxiliar){
    return CachedApi.getCached('ocorrencia-'+registroAuxiliar, async () => {
      return Http.get(`${path}/registro-auxiliar/${registroAuxiliar}/possui-ocorrencias-ativas`);
    });
  },

  async clonar(id) {
    return Http.post(`${path}/${id}/clonar`);
  }

}
