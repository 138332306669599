<template>
  <modal titulo="DAJES Gerados">
    <small-loading :loading="true" v-if="loading" class="py-4"></small-loading>
    <card-content v-if="!loading">
      <table class="table table-striped card-table table-vcenter" v-if="items.length">
        <thead>
          <tr>
            <th class="pl-2">Nº da Guia</th>
            <th class="pl-2">Tipo do ato</th>
            <th class="pl-2">Nota de Desconto/Acréscimo</th>
            <th class="pl-2">Valor</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="'daje-gerado-'+index">
            <td class="pr-2 pointer icon" @click="close(item.numeroControle)">{{item.numeroControle || '-'}}</td>
            <td class="pr-2">{{getTiposTribunal(item) || '-'}}</td>
            <td class="pr-2">{{getNotas(item) || '-'}}</td>
            <td class="pr-2" style="min-width: 100px">{{$formatters.currency(item.valor, 2, 'ISENTO')}}</td>
          </tr>
        </tbody>
      </table>
      <div class="alert alert-outline-info w-100" v-else>
        Nenhum DAJE gerado
      </div>
    </card-content>

  </modal>
</template>

<script>
  import darfBusiness from "@/business/DarfBusiness.js";
  import FrontBusiness from "@/business/FrontBusiness";
  import CardContent from "@/components/Layout/components/CardContent.vue";
  import Modal from "@/components/Modal";
  import SmallLoading from "@/components/SmallLoading.vue";

  export default {
    name: "ModalDajesGeradosProtocolo",
    components: {SmallLoading, Modal, CardContent},
    props: {
      protocolo : String,
    },
    data() {
      return {
        items : [],
        options: {
          tiposTribunal: [],
          notas: [],
        },
        loading : true
      }
    },

    modal: {
      width: 1100
    },

    async mounted() {
      this.items = await this.$api.get(`/api/v2/regras-estaduais/BA/dajes-gerados-protocolo/${this.protocolo}`)
        .catch(e => {
          FrontBusiness.showError('Erro ao tentar consultar DAJES gerados', e?.message || e);
          this.close();
        });
      let atribuicoes = [...new Set(this.items.map(e => e.atribuicao))];
      for (const atribuicao of atribuicoes) {
        this.options.tiposTribunal[atribuicao] = await this.$api.get(`api/v2/regras-estaduais/BA/tipos-ato/${atribuicao}`);
        this.options.notas[atribuicao] = await this.$api.get(`api/v2/regras-estaduais/BA/notas-ato/${atribuicao}`);
      }
      this.loading = false;
    },

    computed:{

    },

    methods:{

      close(resultado) {
        FrontBusiness.closeModal(resultado);
      },

      getTiposTribunal(item) {
        let lista = this.options.tiposTribunal[item.atribuicao || 'RI'];
        return lista.find(t => t.id == item?.codigoTipoAto)?.nome
      },

      getNotas(item) {
        let lista = this.options.notas[item.atribuicao || 'RI'];
        return lista.find(t => t.id == item?.codigoNota)?.nome
      }

    }
  }
</script>

<style>

</style>
