import FrontBusiness from "@/business/FrontBusiness.js";
import TabelaCustaBusiness from "@/business/TabelaCustaBusiness.js";
import VueBusiness from "@/business/VueBusiness.js";
import CachedApi from "@/commons/CachedApi.js";
import Http from "@/commons/Http";
import Utils from "@/commons/Utils.js";
import ValidationUtils from "@/commons/ValidationUtils.js";
import SeeModal from "@/components/SeeModal.vue";
import DominioBusiness from "@/business/protocolo/DominioBusiness";

const path = 'api/protocolo';

export default {

  nomearEntidade(protocolo, code = true){
    return FrontBusiness.nomearProtocolo(protocolo.dominio, protocolo.tipoProtocolo, code ? protocolo.codigo : undefined);
  },

  async getById(id){
    if(!Utils.testUUID(id)){
      return Promise.reject();
    }
    return Http.get(`${path}/${id}`);
  },

  async pagination(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/pagination?sort=${sort || 'p.codigo'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async paginationV2(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/v2/pagination?sort=${sort || 'p.codigo'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async protocolosNaoAtivos(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/protocolos-nao-ativos?sort=${sort || 'p.codigo'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async anotacaoRegistro(id){
    return Http.get(`${path}/${id}/anotacao-registro`);
  },

  async verificarOcorrenciasEnvolvidos(id){
    return Http.get(`${path}/${id}/verificar-ocorrencias-envolvidos`);
  },

  async verificarPepEnvolvidos(id){
    return Http.get(`${path}/${id}/verificar-pep-envolvidos`);
  },

  async verificarPaisesEspeciaisSiscoaf(id){
    return Http.get(`${path}/${id}/verificar-paises-especiais`);
  },

  async save(dto, id = ''){
    return Http.post(`${path}/${id}`, dto);
  },

  async edicaoFormulario(dto, id = ''){
    return Http.post(`${path}/edicao-formulario/${id}`, dto);
  },

  async clonar(id = '', dto){
    return Http.post(`${path}/${id}/clonar`, dto);
  },

  async atualizarBuscas(quantidade, id) {
    return Http.post(`${path}/${id}/prenotacao/${quantidade}`);
  },

  observerAtualizarCustas(protocolo) {
    return `${window.server.API}/${path}/${protocolo}/prenotacao/recalcular/subscribe`;
  },

  atualizarCustas(protocolo) {
    return FrontBusiness.openModal(SeeModal, {titulo : 'Calcular Custas do Protocolo', url : this.observerAtualizarCustas(protocolo)});
  },

  observerSimularCustas(protocolo) {
    return `${window.server.API}/api/v2/protocolo/${protocolo}/simular-valores/subscribe`;
  },

  simularCustas(protocolo) {
    return FrontBusiness.openModal(SeeModal, {titulo : 'Calcular Custas do Protocolo', url : this.observerSimularCustas(protocolo)});
  },

  async livroProtocolo(id){
    return Http.get(`${path}/${id}/livro-protocolo`);
  },

  async salvarLivroProtocolo(protocolo, dto){
    return Http.post(`${path}/${protocolo}/livro-protocolo`, dto);
  },

  async excluirLivroProtocolo(id, dto){
    return Http.post(`${path}/livro-protocolo/excluir/${id}`, dto);
  },

  async regerarOrdem(dto){
    return Http.post(`${path}/livro-protocolo/regerar-ordem`, dto);
  },

  async regerarParte(dto){
    return Http.post(`${path}/livro-protocolo/regerar-parte`, dto);
  },

  async listarPrenotacao(id){
    return Http.get(`${path}/${id}/prenotacao/listar`);
  },

  async inserirPrenotacao(dto, id){
    return Http.post(`${path}/${id}/prenotacao/inserir`, dto);
  },

  async deletarPrenotacao(prenotacao, id){
    return Http.delete(`${path}/${id}/prenotacao/${prenotacao}`);
  },

  async setPrenotacaoPrincipal(prenotacao, id){
    return Http.post(`${path}/${id}/prenotacao/principal/${prenotacao}`);
  },

  async temPrenotacaoSelada(id){
    return Http.get(`${path}/${id}/prenotacao/prenotacao-selada`);
  },

  async getPrenotacaoById(protocolo, id){
    return Http.get(`${path}/${protocolo}/prenotacao/${id}`);
  },



  async registrarAtos(id, minutas) {
    let result = await Http.post(`${path}/${id}/registrar-atos`, minutas, {timeout: 0});
    if(result){
      return FrontBusiness.openModal(SeeModal, {titulo : 'Registrar Protocolo', url : `${window.server.API}/${path}/${id}/registrar-atos/subscribe`});
    }
  },

  async preValidarRegistroProtocolo(id){
    return Http.get(`${path}/${id}/pre-validar-registro`);
  },

  async buscarPorDecursoPrazo(filtros){
    return Http.post(`${path}/buscar-decurso-prazo`, filtros, {timeout: 0});
  },

  async fichasUsadas(id, certidao = false){
    return Http.get(`${path}/${id}/fichas-usadas${certidao ? '/certidao' : ''}`);
  },

  async envolvidosUsados(id){
    return Http.get(`${path}/${id}/envolvidos-usados`);
  },

  async bensMoveisUsados(id){
    return Http.get(`${path}/${id}/bens-moveis-usados`);
  },

  async resumo(id){
    return Http.get(`${path}/${id}/resumo`);
  },

  async getAuditorias(id, page, size){
    return Http.get(`${path}/${id}/auditorias?page=${page || 1}&size=${size || 10}`);
  },

  async atribuidos(){
    return Http.get(`${path}/atribuidos`);
  },

  async protocoloUrgente(){
    return Http.get(`${path}/urgente`);
  },

  async listarProtocolosUrgentes(){
    return Http.get(`${path}/listar-urgentes`);
  },

  async solicitar(qtd= 1, forcarUrgentes = false, etapa){
    return Http.get(`${path}/${qtd}/solicitar${forcarUrgentes ? '/urgente' : etapa ? '/'+etapa : ''}`);
  },

  async solicitarVinculados(ids, solicitar = false) {
    return Http.post(`${path}/solicitar-vinculados${solicitar ? '/true' : ''}`, ids);
  },

  async tarefaDisponivel(geral){
    return CachedApi.getCached('tarefa-disponivel', async () => {
      return Http.get(`${path}/tarefa-disponivel/${geral}`);
    });
  },

  async listarTarefasDisponiveis(etapa){
    return Http.get(`${path}/listar-tarefas-disponiveis/${etapa}`);
  },

  async salvarIndices(id, dto) {
    return Http.post(`${path}/${id}/indices`, dto);
  },

  async buscarPorCodigos(codigos, dominio, tipoProtocolo) {
    if(!dominio || !tipoProtocolo){
      throw 'Intervalo Inválido';
    }
    return await Http.post(`${path}/codigos/${dominio}/${tipoProtocolo}`, codigos);
  },

  async getUltimoAtribuido(id){
    return Http.get(`${path}/${id}/ultimo-atribuido`);
  },

  getTiposRegistro(){
    return TabelaCustaBusiness.listTiposRegistro();
  },

  async getValorTotal(id){
    return Http.get(`${path}/${id}/valor-total`);
  },

  async getValorDiferido(id){
    return Http.get(`${path}/${id}/valor-diferido`);
  },

  async getSaldo(id){
    return Http.get(`${path}/${id}/saldo`);
  },

  async exigencias(id){
    return Http.get(`${path}/${id}/exigencias`);
  },

  async suspenderPrazo(protocolo, dto){
    return Http.post(`${path}/${protocolo}/suspender`, dto);
  },

  async protocoloSeladoItensNaoSelados(protocolo){
    return Http.get(`${path}/${protocolo}/itens-nao-selados`);
  },

  async ordenarItens(protocolo, tipo = 'PROTOCOLO', dto){
    return Http.post(`${path}/${protocolo}/ordenar-itens/${tipo}`, dto);
  },

  async regerarChecklist(protocolo){
    return Http.post(`${path}/${protocolo}/checklist`);
  },

  async getProtocolosPorId(ids) {
    return await Http.post(`${path}/ids`, ids);
  },

  async auditarRecibo(protocolo, dto){
    return Http.post(`${path}/${protocolo}/auditar-recibo`, dto);
  },

  async protocolosVinculadosCom(id){
    return Http.get(`${path}/${id}/vinculo/com`);
  },

  async protocolosVinculados(id){
    return Http.get(`${path}/${id}/vinculo`);
  },

  async inserirVinculo(protocolo, vinculo){
    return Http.post(`${path}/${protocolo}/vinculo/${vinculo}`);
  },

  async deletarVinculo(protocolo){
    return Http.delete(`${path}/${protocolo}/vinculo`);
  },

  async protocolosOriginados(id){
    return Http.get(`${path}/${id}/originados`);
  },

  async desfazerRegistro(protocolo, motivo){
    return Http.post(`${path}/${protocolo}/desfazer-registro`, motivo, {
      headers: { 'Content-Type': 'text/plain' }
    });
  },

  async buscarProtocolosAtivos(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/protocolos-ativos?sort=${sort || 'p.codigo'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async fichasUsadasPagination(sort, direction, page, size, filters = {}, certidao = false){
    return Http.post(`${path}/pagination-fichas-usadas${certidao ? '/certidao' : ''}?sort=${(filters?.filtros.modo == 'FICHA' ? 'codigo' : (sort == 'codigo' && filters?.filtros.modo == 'PROTOCOLO' ? 'protocolo_codigo' : sort)) || (filters?.filtros.modo == 'PROTOCOLO' ? 'protocolo_codigo' : 'codigo')},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async buscarDetalhamentoItemUsado(protocolo, modo, id, certidao = false){
    return Http.get(`${path}/detalhamento-item-usado${certidao ? '/certidao': ''}/${protocolo}/${modo}/${id}`);
  },

  async tags(){
    return Http.get(`${path}/tags`);
  },

  salvarAdiamento(protocolo, dto) {
    return Http.post(`${path}/${protocolo}/salvar-adiamento`, dto);
  },

  async inserirIndicadorRealExterno(dto, protocolo){
    return Http.post(`${path}/${protocolo}/indicador-real-externo`, dto);
  },

  async listarIndicadorRealExterno(protocolo){
    return Http.get(`${path}/${protocolo}/indicador-real-externo/listar`);
  },

  async deletarIndicadorRealExterno(id, protocolo){
    return Http.delete(`${path}/${protocolo}/indicador-real-externo/${id}`);
  },

  async possuiVinculos(id) {
    return Http.get(`${path}/${id}/possui-vinculos`);
  },

  async vencimentoLote(dto){
    return Http.post(`${path}/vencimento-lote`, dto);
  },

  getInteressadosProtocolo(id){
    return Http.post(`${path}/${id}/interessado-protocolo`)
  },

  getConjugeInteressado(id){
    return Http.post(`${path}/${id}/conjuge-interessado`)
  },

  recalcularVencimento(id) {
    return Http.post(`${path}/${id}/recalcular-vencimento`);
  },

  async simularValidacao(protocolo){
    return Http.get(`${path}/${protocolo}/simular-validacao`);
  },

  getTiposProtocolo(simples) {

    if(simples){
      return [
        {id: 'NORMAL', nome: 'Normal'},
        {id: 'PROCESSO_INTERNO', nome: 'Processo Interno'}
      ];
    }
    return [
      {id: 'NORMAL', nome: 'Normal'},
      {id: 'EXAME_CALCULO', nome: 'Exame e Cálculo'},
      {id: 'PROCESSO_INTERNO', nome: 'Processo Interno'},
      {id: 'ORCAMENTO', nome: 'Orçamento'}
    ];
  },

  getProtcolos(){
    return [
      {id: 'RI', nome: 'RI'},
      {id: 'RTD', nome: 'RTD'},
      {id: 'RCPJ', nome: 'RCPJ'},
    ]
  },

  getLivros(tipo){
    let options = [];

    if(tipo === 'indicadorRI'){
      options.push({id: 'livro-matricula', nome: "RI - Livro 2"});
      options.push({id: 'livro-registro-auxiliar', nome: "RI - Livro 3"});
      options.push({id: 'transcricao', nome: "RI - Transcrição"});
    }

    if(tipo === 'indicadorRTD'){
      options.push({id: 'livro-rtd-B', nome: "RTD - Livro B"});
      options.push({id: 'livro-F', nome: "RTD - Livro F"});
      options.push({id: 'livro-C', nome: "RTD - Livro C"});
    }

    if(tipo === 'indicadorRCPJ'){
      options.push({id: 'livro-rcpj-B', nome: "RCPJ - Livro B"});
      options.push({id: 'livro-A', nome: "RCPJ - Livro A"});
    }
    return options;
  },

  getTiposCertidao() {
    return [
      {id: 'NORMAL', nome: 'Normal'},
      {id: 'ORCAMENTO', nome: 'Orçamento'}
    ];
  },

  getPrioridades() {
    return [
      // {id: 'BAIXA', nome: 'Baixa'},
      {id: 'NORMAL', nome: 'Normal'},
      {id: 'URGENTE', nome: 'Urgente'}
    ];
  },

  getStatus() {
    return [
      {id: 'VIGENTE', nome: 'Vigente'},
      {id: 'CANCELADO', nome: 'Cancelado'},
      {id: 'FINALIZADO', nome: 'Finalizado'}
    ];
  },

  getTipoCancelamento() {
    return [
      {id: 'ABERTURA_INDEVIDA', nome: 'Abertura indevida'},
      {id: 'DECURSO_LEGAL', nome: 'Decurso legal do prazo'},
      {id: 'DESISTENCIA_PARTE', nome: 'Desistência da parte'},
      {id: 'FALTA_DE_PAGAMENTO', nome: 'Falta de pagamento'},
      {id: 'ORDEM_JUDICIAL', nome: 'Ordem judicial'},
      {id: 'OUTRO_MOTIVO', nome: 'Outro motivo'},
    ]
  },

  getTipoCancelamentoAutomatico(cancelamento = 'Cancelar automaticamente no decurso de prazo') {
    return [
      {id: 'CANCELAR',            nome: cancelamento},
      {id: 'BEM_FAMILIA',         nome: 'Bem de família'},
      {id: 'INTIMACAO_ALIENACAO', nome: 'Intimação de alienação fiduciária'},
      {id: 'LOTEAMENTO',          nome: 'Loteamento'},
      {id: 'MANDADO_JUDICIAL',    nome: 'Mandado judicial'},
      {id: 'OFICIO_JUIZO',        nome: 'Ofício ao juízo'},
      {id: 'PROTOCOLO_POSTERIOR', nome: 'Protocolo posterior'},
      {id: 'EDITAL',              nome: 'Publicação de edital'},
      {id: 'RETIFICACAO_AREA',    nome: 'Notificação de confrontante em retificação de registro'},
      {id: 'SEGUNDA_HIPOTECA',    nome: 'Segunda hipoteca'},
      {id: 'SUSCITACAO_DUVIDA',   nome: 'Suscitação de dúvida'},
      {id: 'USUCAPIAO',           nome: 'Usucapião'},
      {id: 'IMPUGNACAO',           nome: "Impugnação"},
      {id: 'ADJUDICACAO',          nome: "Adjudicação"},
      {id: 'COBRANCA_EMOLUMENTOS', nome: "Cobrança de emolumentos"},
      {id: 'PARCELAMENTO_SOLO',    nome: "Parcelamento do solo"},
      {id: 'RETIFICACAO_REGISTRO', nome: "Retificação de registro"},
      {id: 'REURB',                nome: "REURB"},
      {id: 'OUTRO_MOTIVO',        nome: 'Outros motivos'},
    ]
  },

  getNaturezasDOI() {
    return [
      {id: 'INSTRUMENTO_PARTICULAR', nome: "Instrumento particular com força de escritura pública"},
      {id: 'ESCRITURA_PUBLICA', nome: "Escritura Pública"},
      {id: 'TITULO_JUDICIAL', nome: "Título Judicial"},
      {id: 'CONTRATOS_TERMOS', nome: 'Contratos ou termos administrativos'},
      {id: 'PAISES_ESTRANGEIROS', nome: 'Atos autênticos de países estrangeiros'},
    ]
  },

  getLabelServicoPrincipal(dominio){
    switch (dominio) {
      // case 'PROTOCOLO_RI':
      //   return 'Ato Principal';
      // case 'CERTIDAO_RI':
      // case 'CERTIDAO_RTD':
      // case 'CERTIDAO_RCPJ':
      //   return 'Certidão Principal';
      case 'OFICIO':
        return 'Tipo de Ofício';
      default:
        return 'Serviço Principal';
    }
  },

  papeisEnvolvidos(dominio, tipoProtocolo = 'NORMAL'){
    if(tipoProtocolo == 'PROCESSO_INTERNO'){
      return ['solicitante'];
    }
    return ['interessado', 'solicitante', 'tomador'];
  },

  tipoOcorrencia(){

    let exigencia = '';
    let registro = '';

    if(VueBusiness.getVueRoot().isEstadoCE){
      exigencia = 'Nota Devolutiva';
      registro = 'Protocolizado';
    } else {
      exigencia = 'Exigência';
      registro = 'Registrado';
    }

    return {
      'CADASTRO' : 'Prenotado',
      'AGUARDANDO_PAGAMENTO' : 'Aguardando Pagamento',
      'CANCELAMENTO' : 'Cancelado',
      'REGISTRO' : `${registro}`,
      'REGISTRO_PARCIAL' : 'Registrado Parcialmente',
      'ENTREGA' : 'Entrega',
      'EXIGENCIA' : `${exigencia}`,
      'REATIVACAO' : 'Reativado',
      'REINGRESSO' : 'Reingressado',
      'REINGRESSO_ERRO' : 'Reingressado',
      'LEGADO' : 'Legado',
      'SUSPENSAO' : 'Suspensão de Vencimento',
      'OUTRAS_OCORRENCIAS' : 'Outras Ocorrências',
      'ENCERRAMENTO': 'Encerrado',
      'CONCLUSAO': 'Concluído'
    }
  },

  getTiposOcorrencia() {
    let exigencia = '';
    let registro = '';

    if(app.__vue__.$root.config.estado == 'CE'){
      exigencia = 'Nota Devolutiva';
      registro = 'Protocolizado';
    } else {
      exigencia = 'Exigência';
      registro = 'Registrado';
    }

    return [
      {id: 'CADASTRO', nome: 'Prenotado'},
      {id: 'AGUARDANDO_PAGAMENTO', nome:'Aguardando Pagamento'},
      {id: 'CANCELAMENTO', nome: 'Cancelado'},
      {id: 'REGISTRO', nome: `${registro}`},
      {id: 'REGISTRO_PARCIAL', nome: 'Registrado Parcialmente'},
      {id: 'ENTREGA', nome: 'Entrega'},
      {id: 'EXIGENCIA', nome: `${exigencia}`},
      {id: 'REATIVACAO', nome: 'Reativado'},
      {id: 'REINGRESSO', nome: 'Reingressado'},
      {id: 'REINGRESSO_ERRO', nome: 'Reingressado'},
      {id: 'LEGADO', nome: 'Legado'},
      {id: 'SUSPENSAO', nome: 'Suspensão de Vencimento'},
      {id: 'OUTRAS_OCORRENCIAS', nome: 'Outras Ocorrências'},
      {id: 'ENCERRAMENTO', nome: "Encerrado"},
      {id: 'CONCLUSAO', nome: "Concluído"}
    ]
  },

  getTiposCamposMatriculas(tipo){
    let campos = [
      {id : 'codigo', nome : 'Matrícula'},
      {id : 'tipoImovel', nome : 'Tipo de Imóvel'},
      {id : 'unidade', nome : 'Número da Unidade'},
      {id : 'bloco', nome : 'Bloco'},
      {id : 'quadra', nome : 'Quadra'},
      {id : 'lote', nome : 'Lote'},
      {id : 'andar', nome : 'Andar'},
      {id : 'pavimento', nome : 'Pavimento'},
      {id : 'localizacao', nome : 'Localização'},
    ]
    if(tipo == 'CERTIDAO') campos.unshift({id : 'tipoCertidao', nome : 'Tipo de Certidão'})
    return campos
  },

  getTipoCusta(){
    return [
      {id: 'CERTIDAO', nome: 'Certidão'},
      {id: 'BUSCA', nome: 'Busca'},
      {id: 'PRENOTACAO', nome: 'Prenotação'},
      {id: 'ATO', nome: 'Ato'},
      {id: 'ADICIONAL', nome: 'Adicional'},
    ]
  },

  getMoedas() {
    return [
      {id : 'CRUZEIRO', nome : 'Cruzeiro (Cr$)'},
      {id : 'CRUZEIRO_NOVO', nome : 'Cruzeiro Novo (NCr$)'},
      {id : 'CRUZADO', nome : 'Cruzado (Cz$)'},
      {id : 'CRUZADO_NOVO', nome : 'Cruzado Novo (Ncz$)'},
      {id : 'CRUZEIRO_REAL', nome : 'Cruzeiro Real (CR$)'},
      {id : 'REAL', nome : 'Real (R$)'},
    ]
  },

  getIndiceCorrecao() {
    return [
      { id : "ESTADO", nome : "Tabela Estadual"},
      { id : "00189IGP-M", nome : "IGP-M (FGV) - a partir de 06/1989"},
      { id : "00190IGP-DI", nome : "IGP-DI (FGV) - a partir de 02/1944"},
      { id : "00188INPC", nome : "INPC (IBGE) - a partir de 04/1979"},
      { id : "00433IPCA", nome : "IPCA (IBGE) - a partir de 01/1980"},
      { id : "10764IPC-E", nome : "IPCA-E (IBGE) - a partir de 01/1992"},
      { id : "00191IPC-BRASIL", nome : "IPC-BRASIL (FGV) - a partir de 01/1990"},
      { id : "00193IPC-SP", nome : "IPC-SP (FIPE) - a partir de 11/1942"},
    ]
  },

  getSituacoesConstrucao() {
    return [
      {id : 'CONSTRUCAO_AVERBADA', nome : 'Construção averbada'},
      {id : 'EM_CONSTRUCAO', nome : 'Em construção'},
      {id : 'NAO_SE_APLICA', nome : 'Não se aplica'},
    ]
  },

  getFormasAlienacao() {
    return [
      {id : 'A_VISTA', nome : 'Quitado à vista'},
      {id : 'QUITADO_A_PRAZO', nome : 'Quitado a prazo'},
      {id : 'QUITADO_SEM_FORMA_PAGAMENTO', nome : 'Quitado sem informação da forma de pagamento'},
      {id : 'A_PRAZO', nome : 'A prazo'},
      {id : 'NAO_SE_APLICA', nome : 'Não se aplica'},
    ]
  },

  getTiposTransacao() {
    return [
      {id : 'COMPRA_E_VENDA', nome : 'Compra e Venda'},
      {id : 'PERMUTA', nome : 'Permuta'},
      {id : 'DACAO_EM_PAGAMENTO', nome : 'Dação em pagamento'},
      {id : 'FUSAO_DE_EMPRESAS', nome : 'Fusão de empresas'},
      {id : 'PROMESSA_DE_COMPRA_E_VENDA', nome : 'Promessa de compra e venda'},
      {id : 'INTEGRALIZACAO_DE_CAPITAL', nome : 'Integralização de capital social de empresas'},
      {id : 'OUTRAS_TRANSACOES_ONEROSAS', nome : 'Outras transações onerosas'},
      {id : 'EXTINCAO_DE_EMPRESA', nome : 'Extinção de empresa'},
      {id : 'CISAO_DE_EMPRESA', nome : 'Cisão de empresa'},
      {id : 'INCORPORACAO_DE_EMPRESAS', nome : 'Incorporação de empresas'},
      {id : 'SUCESSAO', nome : 'Sucessão'},
      {id : 'MEACAO', nome : 'Meação'},
      {id : 'OUTRAS_TRANSACOES_NAO_ONEROSAS', nome : 'Outras transações não onerosas'},

    ]
  },

  tratarDTO(protocolo){

    const dto = Utils.clone(protocolo);

    delete dto.proximas;
    const campos = ['etapa', 'atendente', 'solicitante', 'natureza', 'tipoServico', 'interessado',
      'tomador', 'usuario', 'origem', 'escrevente', 'indisponibilidadeOrdem', 'concedenteRegistro'];

    campos.forEach(c => {
      if(dto[c]?.id){
        dto[c] = {id : dto[c].id};
      }else{
        dto[c] = null;
      }
    });
    dto.tags = dto.tags?.map(tag => tag?.nome || tag).filter(e => e);

    return dto;


  },

  mensagemAlerta(envolvidos, dominio){
    let papel;
    if(DominioBusiness.isDominioRTD(dominio)){
      if(envolvidos.length === 1){
        papel = "o notificado"
      } else {
        papel = "os notificados"
      }
    }

    if(DominioBusiness.isDominioRCPJ(dominio)){
      if(envolvidos.length === 1){
        papel = "a pessoa jurídica principal"
      } else {
        papel = "as pessoas jurídicas principais"
      }
    }

    if(envolvidos.length === 1){
      let nome;
      envolvidos.forEach(n => {
        nome = n.nome;
      })
      return `Existem outros protocolos em andamento com ${papel} deste protocolo (${nome})`
    }

    let mensagem = `Existem outros protocolos em andamento com ${papel} deste protocolo (`;

    for(let i = 0; i < envolvidos.length; i++){
      mensagem += envolvidos[i].nome;

      if (i !== envolvidos.length - 1) {
        mensagem += ", ";
      }
    }
    mensagem += ")";
    return mensagem;
  },

  getErrosEnvolvidos(protocolo, papeis){

    var erros = [];
    papeis.forEach(p => {
      if (protocolo[p.toLowerCase()]?.id) {
        let documento = protocolo[p.toLowerCase()].documento ? (protocolo[p.toLowerCase()].documento).replace(/\D/g,'') : null;
        if (!documento) {
          erros[protocolo[p.toLowerCase()].id] = "Inspeção Virtual: Parte envolvida sem CPF/CNPJ informado";
        }
        if (protocolo[p.toLowerCase()].nome.trim().split(" ").length == 1) {
          erros[protocolo[p.toLowerCase()].id] = "Inspeção Virtual: Nome informado para a parte envolvida está incompleto";
        }
      }
    });
    return erros;
  },

  async getTipoInsencaoItens() {
    return CachedApi.getCached("tipo-isencao",async ()=>{
      return  Http.get(`${path}/tipo-isencao-itens`);
    },false);
  },

  verifyIfUpdateIsPermitted(protocolo){
    return Http.get(`${path}/${protocolo}/verify-if-update-data-selagem-is-permitted`);
  },

  getCodigoProtocolo(protocolo,separador = false){
    let letra  = separador && protocolo.letra ? "-".concat(protocolo.letra): !separador && protocolo.letra ? protocolo.letra:'';
    return protocolo.codigo.toString().concat(letra);
  },

  tipoProtocoloPermiteSelar(tipoProtocolo) {
    const { isEstadoBA, config } = VueBusiness.getVueRoot();
    const tipos = ['NORMAL'];

    if (config.geral.permitirSelagemProcessoInterno) {
      tipos.push('PROCESSO_INTERNO');
    }

    if (isEstadoBA) {
      tipos.push('EXAME_CALCULO');
    }

    return tipos.includes(tipoProtocolo);
  }

}
